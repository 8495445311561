<template>

<app-wrapper theme="blue">

	<app-wrapper :is-padded="true" :is-content="true" title="Latest news & notable cases" class="news">

		<app-article :is-slim="true" class="news-item" v-for="(item, index) in $state.page.news" :key="index" :item="item" />

		<app-button text="View All" :to="{name: 'News'}" class="news-button" />

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.news {
	max-width: 1060px!important;
	margin: 0px auto;
}

.news-item {
	margin-bottom: 20px;
}

.news-item:last-child {
	margin-bottom: 0px;
}

.news-button {
	margin-top: 64px;
}

.is-tablet .news-button {
	margin-top: 48px;
}

.is-mobile .news-button {
	margin-top: 32px;
	width: 100%;
}

</style>
